import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgIf } from '@angular/common';
import { ValidationErrors } from '@angular/forms';
import { localizePlural } from '@parashift/shared/utils';

@Component({
  selector: 'validation-errors',
  templateUrl: './validation-errors.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf]
})
export class ValidationErrorsComponent {

  @Input() errors: ValidationErrors;
  @Input() label: string;

  requiredError() {
    const fieldLabel = this.label || $localize `:@@common.empty_field:This field`;
    return $localize `:@@shared.components.forms.validators.required:${fieldLabel}:field: is required`;
  }

  minlengthError() {
    return $localize `:@@shared.components.forms.validators.min_length:Should have at least ${
      localizePlural(this.errors.minlength.requiredLength, {
        0: $localize `${this.errors.minlength.requiredLength}:characters: characters`,
        1: $localize `${this.errors.minlength.requiredLength}:character: character`,
        other: $localize `${this.errors.minlength.requiredLength}:characters: characters`,
      })
    }:pluralCharacters:.`;
  }

  maxlengthError() {
    return $localize `:@@shared.components.forms.validators.max_length:Should have at least ${
      localizePlural(this.errors.maxlength.requiredLength, {
        0: $localize `${this.errors.maxlength.requiredLength}:characters: characters`,
        1: $localize `${this.errors.maxlength.requiredLength}:character: character`,
        other: $localize `${this.errors.maxlength.requiredLength}:characters: characters`,
      })
    }:pluralCharacters:.`;
  }

  minError() {
    return $localize `:@@shared.components.forms.validators.min:This value should be more than or equal ${this.errors.min.min}:number:`;
  }

  maxError() {
    return $localize `:@@shared.components.forms.validators.max:This value should be less than or equal ${this.errors.max.max}:number:`;
  }

  jsonError() {
    return $localize `:@@shared.components.forms.validators.json:Invalid JSON`;
  }

  parameterNameError() {
    return $localize `:@@shared.components.forms.validators.parameter_name:Invalid parameter name`;
  }

  mimeTypeFormatError() {
    return $localize `:@@shared.components.forms.validators.mime_type_format:Please use pattern 'type/subtype[+suffix]' at line: ${this.errors['mime-type-format']}:lineNumbers:`;
  }

  emailError() {
    return $localize `:@@shared.components.forms.validators.email:This is not a valid email address`;
  }

  passwordConfirmError() {
    return $localize `:@@shared.components.forms.validators.password_confirm:Passwords must be identical!`;
  }


  // private emailValidationMessage(err, field: FormlyFieldConfig) {
  //   return $localize `:@@shared.components.forms.validators.email:${field.formControl.value}:email: is not a valid email address`;
  // }

  // private patternValidationMessage() {
  //   return $localize `:@@shared.components.forms.validators.pattern:Invalid format`;
  // }

  // private valueValidationMessage() {
  //   return $localize `:@@shared.components.forms.validators.value:Invalid value`;
  // }

  // private dateValidationMessage() {
  //   return $localize `:@@shared.components.forms.validators.date:Invalid date`;
  // }

  // private datetimeValidationMessage() {
  //   return $localize `:@@shared.components.forms.validators.datetime:Invalid date or time`;
  // }
}
