import { DestroyRef, Directive, Inject, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlContainer, ControlValueAccessor, FormControl, FormControlDirective, Validators } from '@angular/forms';

@Directive()
export abstract class BaseField implements ControlValueAccessor, OnInit {

  @ViewChild(FormControlDirective) set fcDirective(directive: FormControlDirective) {
    if (directive) {
      this.formControlDirective = directive;
    }
  }

  @Input() formControlName: string;
  @Input() label: string;
  @Input() readonly = false;

  required = false;

  private formControlDirective: FormControlDirective

  get control() {
    return this.controlContainer.control.get(this.formControlName) as FormControl;
  }

  get controlContainer() {
    return this.injector.get(ControlContainer);
  }

  constructor(
    protected injector: Injector,
    @Inject(DestroyRef) protected destroyRef: DestroyRef
  ) {}

  ngOnInit() {
    this.setRequiredState();
    this.listenToStatusChanges();
  }

  writeValue(value: any) {
    this.formControlDirective?.valueAccessor.writeValue(value);
  }

  registerOnChange(fn: (value: any) => void) {
    this.formControlDirective?.valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: () => void) {
    this.formControlDirective?.valueAccessor.registerOnTouched(fn);
  }

  setDisabledState(disabled: boolean) {
    this.formControlDirective?.valueAccessor.setDisabledState(disabled);
  }

  private listenToStatusChanges() {
    this.control?.statusChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => this.setRequiredState());
  }

  private setRequiredState() {
    this.required = this.control?.hasValidator(Validators.required);
  }
}
